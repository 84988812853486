import React from "react";
import Products from "../products";
import {
  SatinadoTitulo,
  Satinado,
  DecoradoTitulo,
  Decorado,
} from "../../images/productos/procesos-especiales/index";
import {
  BotellaPersonalizadaTitulo,
  BotellaPersonalizada,
} from "../../images/productos/area-premium/index";
import SEO from "../../components/SEO/Seo";

const ProcesosEspeciales = () => {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location =
  //     "http://www.blueskysa.com.ar/movile/20-pintado-impresas.html";
  // }
  return (
    <>
      <SEO
        title="Blue Sky SA -  Satinado de botellas"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  ,Blue Sky SA , Procesos adicionales , Satinado de botellas. Arenado , Pintado de botellas. Colores varios. Botellas coloreadas , Argentina."
        description=" Procesos adicionales - Satinado de botellas. Arenado - Pintado de botellas. Colores varios. Botellas coloreadas - Argentina."
      />
      <Products subtitle="Procesos Especiales">
        <div className="mb-3" align="center">
          <img
            src={SatinadoTitulo}
            width="900"
            height="56"
            alt="Titulo Satinado de botellas"
          />{" "}
          <img
            src={Satinado}
            width="900"
            height="409"
            alt="Satinado de Botellas"
          />
        </div>
        <div align="center" className="mb-3">
          <img
            src={DecoradoTitulo}
            width="900"
            height="56"
            alt="Titulo Decorado de botellas"
          />{" "}
          <img
            src={Decorado}
            width="900"
            height="1157"
            alt="Decorado de Botellas"
          />
        </div>
        <div className="mb-3" align="center">
          <img
            src={BotellaPersonalizadaTitulo}
            width="900"
            height="58"
            alt="Botella Personalizada Titulo"
          />
          <img
            src={BotellaPersonalizada}
            width="900"
            height="1600"
            alt="Botella Personalizada"
          />
        </div>
      </Products>
    </>
  );
};

export default ProcesosEspeciales;
